.ticket-section{
    position: fixed;
    background-color: white;
    padding: 15px;
    right: 10px;
    top: 20vh;
    width: 350px;
    border-radius: 10px;
    
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.ticket-section-item-container{
    width: 100%;
    margin: 10px;
    display: flex;
    align-items: center;
}
.ticket-label{
    font-size: 17px;
    width: 50%;
}
.ticket-input{
    width: 50%;
    height: 32px;
}
.input-date{
    font-size: 18px;
}