.email-container{
    padding:  10px 10px;
}
.subject{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.subject label{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 15px;
}
.subject input{
   height: 30px; 
   font-size: 17px;
   padding: 3px 8px;
}
.subject textarea{
    height: 100px;
    padding: 3px 8px;
    font-size: 18px;
}


.email-container button{
    
    font-family: inherit;
    font-size: 20px;
    background: royalblue;
    color: white;
    padding: 0.7em 1em;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
    height: 35px;
    border-radius: 10px;
    font-size: 18px;
    margin-top: 15px;
  }
  
  .email-container button span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
  }
  
  .email-container button svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
    height: 20px;
  }
  
  .email-container button:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }
  
  .email-container button:hover svg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
  }
  
  .email-container button:hover span {
    transform: translateX(5em);
  }
  
  .email-container button:active {
    transform: scale(0.95);
  }
  
  @keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }
  
    to {
      transform: translateY(-0.1em);
    }
  }
  