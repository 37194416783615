.customer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.customer-search {
    outline: none;
    width: 300px;
    height: 30px;
    padding: 3px 5px;
    border: none;
    margin-left: 10px;
    font-size: 17px;
}

.customer-search-container {
    display: flex;
    align-items: center;
    border: 1px solid;
    margin: 15px;
    padding: 5px 8px;
    border-radius: 8px;
}

.customer-list-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-self: center;
}

.customer-list {
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
    padding: 10px;
    margin: 10px;
    width: 30%;
    border-radius: 10px;
    cursor: pointer;
    box-sizing: border-box;
}

.customer-list-item {
    color: black;
    display: flex;
    
}
.customer-list-item p{
    word-break: break-all;
    width: 40%;
    font-weight: 600;
    margin: 10px 4px;
}
.customer-list-p{
    width: 60%;
    flex-grow: 1;
    
}

.search-addcustomer-section {
    display: flex;
    align-items: center;
}

.customerDetails-add-customer-button {
    height: 30px;
    cursor: pointer;
}