.editpage-container{
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(235, 221, 221);
    padding: 43px;
}

.editpage-form-container{
    width:600px;
    background-color: #f8f9fa;
    padding: 13px;
    border-radius: 12px;
    min-height:50vh ;
    display: flex;
    flex-direction: column;
}
.edit-form-item-container{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin: 10px;
}
.edit-form-label{
    width: 200px;
    font-weight: 600;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.edit-form-input{
    
   border: none;
   border-bottom:1px solid ;
   background-color: transparent;
   width:350px;
   outline: none;
   height: 34px;
   font-size: 16px;
}

.update-button{
    height: 34px;
    width: 130px;
    border: none;
    border-radius: 10px;
    align-self: center;
    margin-top: 30px;
    margin-bottom: 10px;
    background-color: #1b1464;
    color: #f8f9fa;
    font-weight: bold;
    cursor: pointer;
}
.edit-page-heading{
    margin: 10px;
    align-self: center;
    margin-bottom: 20px;
}
.edit-hr{
    border: 1px solid rgb(116, 89, 89);
    align-self: stretch;
    width: 100%;
}
.notification-popup{
    padding: 10px;
    border: none;
    height: 30px;
    position: absolute;
    top:90px;
    right:8px;
    display: flex;
    align-items: center;

    background-color: #ffffff;
}



.bg-success{
    color: #dc3545;
    margin-left: 10px;
}
.bg-danger{
    color: #F40303FF;
    margin-left: 10px;
}